import imageProperties from '../lib/image-properties'
import binaryProperties from '../lib/binary-properties'

const createQuery = () => `
  ... on HeroWidget {
    id
    images {
      hero_2x1_600 {
        ${imageProperties}
      }
      hero_2x1_800 {
        ${imageProperties}
      }
      hero_2x1_1000 {
        ${imageProperties}
      }
      hero_2x1_1280 {
        ${imageProperties}
      }
      hero_2x1_1600 {
        ${imageProperties}
      }
      hero_2x1_2000 {
        ${imageProperties}
      }
      hero_2x1_2500 {
        ${imageProperties}
      }
    }
    videoAssets: assets {
      video {
        ${binaryProperties}
      }
    }
    videoAssetsMobile: assetsMobile {
      video {
        ${binaryProperties}
      }
    }
  }
`
export default createQuery
