const createQuery = () => `
... on RecommendationWizardWidget {
  id
  title
  description
  ctaText
  articleLimit
  strategyLimit
  skillOptions {
    label
    value
  }
  ageOptions {
    label
    value
  }
}
`
export default createQuery
