const createQuery = () => `
  ... on SuperHeroWidget {
    kioskTopLabel
    kioskTopLink
    kioskBottomLabel
    kioskBottomLink
    leftBuoyLabel
    leftBuoyLink
    rightBuoyLabel
    rightBuoyLink
  }
`
export default createQuery
