import renderResource from '../resource/render-resource'

import widgetArea from '../widget/widget-area'

import createSectionLayoutWidgets from './lib/layout-widgets'
import widgetDefaultParams from './lib/widget-default-params'
import buildDefaultParameters from './lib/build-query'

const layout = `
layout {
  attributes
  cols {
    width
    widgetArea {
      ${widgetArea(createSectionLayoutWidgets())}
    }
  }
}
`
const createQuery = () => {
  const sectionQuery = `...on Section {
    _id
    name
    slug
    fullUrlPath
    color1
    color2
    displayGeolocationBar
    displayFlag
    accentColor
    metaTitle
    metaDescription
    shareTitle
    shareDescription
    layouts {
      section {
        ${layout}
      }
      product {
        ${layout}
      }
    }
    subNavItems {
      name
      link
    }
  }`
  return renderResource(
    sectionQuery,
    buildDefaultParameters(sectionQuery, widgetDefaultParams)
  )
}

export default createQuery
