import React from 'react'
import { func, object } from 'prop-types'

import retrieveAndProcessDataForPage from '@/lib/data-for-page-processor-and-retriever'
import createRenderSectionQuery from '@/query/section/render-section'
import StandardMeta from '@/component/Meta/Standard'

import Layout from '@/component/Structure/Layout'
import { useVersionPath } from '@/component/Context/VersionPathContext'

const SectionPage = ({ __url, section, pageData }) => {
  const versionPath = useVersionPath()
  if (!section) return null
  const layout = section?.layouts?.section?.layout

  return (
    <>
      <StandardMeta
        title={section.metaTitle || section.name}
        description={section.metaDescription}
        seoTitle={section.shareTitle || section.metaTitle || section.name}
        seoDescription={section.shareDescription || section.metaDescription}
        canonicalUrl={pageData.url}
        images={[
          {
            url: versionPath('/public/image/NessyLogo.png'),
            width: 1920,
            height: 768,
            alt: section.metaTitle || section.name
          }
        ]}
      />
      <Layout
        layout={layout}
        __url={__url}
        section={section}
        pageData={pageData}
      />
    </>
  )
}

SectionPage.propTypes = {
  __url: func.isRequired,
  section: object,
  pageData: object
}

SectionPage.getInitialProps = async (context) => {
  const params = context && context.query
  const query = createRenderSectionQuery()
  const page = parseInt(params && params.page) || 1
  const data = await retrieveAndProcessDataForPage(context, query, { page })
  if (data.error) return data
  const section = data && data.response && data.response.resource
  const url = data && data.vars && data.vars.url
  const pageData = { url, params }
  return {
    ...data.response,
    section,
    pageData
  }
}

export default SectionPage
