import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on BannerImageWidget {
    title
    ctaLink
    images {
      large_5x1_1048 {
        ${imageProperties}
      }
      large_5x1_800 {
        ${imageProperties}
      }
      large_5x1_600 {
        ${imageProperties}
      }
      small_16x9_600 {
        ${imageProperties}
      }
      small_16x9_400 {
        ${imageProperties}
      }
    }
  }
`
export default createQuery
