import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on PromoDoubleSquaresWidget {
    title
    ctaLink
    titleSecondary
    ctaLinkSecondary
    text
    images {
      primary_1x1_1000 {
        ${imageProperties}
      }
      primary_1x1_800 {
        ${imageProperties}
      }
      primary_1x1_600 {
        ${imageProperties}
      }
      secondary_1x1_1000 {
        ${imageProperties}
      }
      secondary_1x1_800 {
        ${imageProperties}
      }
      secondary_1x1_600 {
        ${imageProperties}
      }
    }
  }
`
export default createQuery
