const createQuery = () => `
... on GettingStartedContentWidget {
  id
  linksTitle
  titleColor
  links {
    label
    link
    linkColor
  }
}
`
export default createQuery
