const createQuery = () => `
  ... on PromoDoubleWidget {
    title
    text
    ctaLink
    ctaText
    titleSecondary
    textSecondary
    ctaLinkSecondary
    ctaTextSecondary
  }
`
export default createQuery
