const createQuery = () => `
  ... on VideoSwitcherWidget {
    id
    videos {
      images {
        thumbnail_1x1_200 {
          url
          caption
          link
        }
        preview_16x9_400 {
          url
          caption
          link
        }
        preview_16x9_800 {
          url
          caption
          link
        }
        preview_16x9_1048 {
          url
          caption
          link
        }
      }
      provider
      videoId
      title
      subtitle
      attributionName
      attributionCompany
    }
  }
`
export default createQuery
